@import '@splidejs/react-splide/css';

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.custom-number-input[type="number"]::-webkit-inner-spin-button,
.custom-number-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  }
/* Estilos para la flechita en los botones de navegación */
.swiper-button-prev{
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 4px; /* Ajusta según tus necesidades */
  padding: 5px;
  transform: translateY(-50%)
}
.swiper-button-next {
  right: 4px; /* Ajusta según tus necesidades */
  z-index: 999;
  position: absolute;
  top: 50%;
  padding: 5px;
  transform: translateY(-50%)
}
.swiper-button-prev::before,
.swiper-button-next::before {
  content: "";
  width: 0;
  height: 0;
  z-index: 999;
  position: absolute;
  top: 50%;
  border-style: solid;
  transform: translateY(-50%);
}

.swiper-button-prev::before {
  border-width: 6px 8px 6px 0;
  border-color: transparent #333 transparent transparent;
}

.swiper-button-next::before {
  border-width: 6px 0 6px 8px;
  border-color: transparent transparent transparent #333;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* datatable */
.sc-dIouRR.TUEFO{
  padding: 0px !important;
}

.rdt_Pagination .sc-llJcti.gnaqej svg{
  display: none;
}

.application__log > span + span {
  display: none;
}

.__react_component_tooltip {
  border-radius: 5px !important;
  padding: 6px !important;
  max-width: 250px !important;
}

.__react_component_tooltip::before{
  height: 0 !important;
}

.slide-files .splide {
  padding: 0;
}

.slide-files .splide__track {
  height: 100%;
}